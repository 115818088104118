<template>
  <v-container class="h-100">
    <v-row class="text-center h-100" justify="center" align="center">
      <v-col class="mb-4 text-center">
        <h1 class="display-3 font-weight-bold mb-3">
         AQiLA
        </h1>
        <h2 class="display-1 font-weight-bold mb-3">
         You are currently at <b>Table {{tableNumber}}</b>
        </h2>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import TableMixins from '../mixins/TableValidation'

  export default {
    name: 'Table',
    mixins: [TableMixins],
    data: () => ({
    }),
  }
</script>


<style>
.h-100 {
  height: 100%;
}
</style>