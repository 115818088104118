export default {
  computed: {
    tableNumber: {
      get: function () {
        return this.$route.params.table
      }
    }
  },
  created () {
    this.tableNumber > 0 && this.tableNumber <= 20 ? null : this.$router.push('/')
  },
}