<template>
  <v-bottom-navigation
    class="accent"
    color="secondary"
    fixed
    app
    shift
    grow
  >
      <v-btn
      class="transparent h-100"
      exact
      :to="`/${!noTable ? table : ''}`"
      >
        <span>Home</span>

        <v-icon>mdi-home</v-icon>
      </v-btn>
    <v-btn
     class="transparent h-100"
     :to="`/${table}/menu/`"
     :disabled="noTable"
     >
      <span>Menu</span>

      <v-icon>mdi-clipboard-edit-outline</v-icon>
    </v-btn>
    <v-btn
      class="transparent h-100"
      :to="`/${table}/recents/`"
      :disabled="noTable"
     >
      <span>Recents</span>

      <v-icon>mdi-history</v-icon>
    </v-btn>
    <!-- {{table}} -->
  </v-bottom-navigation>

</template>

<script>
export default {
  computed: {
    noTable: {
      get: function () {
        return this.$route.params.table ? false : true;
      }
    },
    table : {
      get: function () {
        return this.$route.params.table
      }
    }
  }
}
</script>
<style scoped>
.tranparent {
 background: transparent !important;
}

.h-100 {
  height: 100% !important;
}
</style>