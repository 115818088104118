<template>
  <v-container class="h-100">
    <v-row class="text-center h-100" justify="center" align="center">

      <v-col class="mb-4 text-center">
        <h1 class="display-2 font-weight-bold mb-3">
         AQiLA
        </h1>

        <p class="subheading font-weight-regular">
          Welcome to our app please enter your table number to continue
        </p>
        <v-container style="width: 80%">
          <v-form @submit.prevent="changeTable">
            <v-text-field 
              label="Table Number" 
              v-model="tableNumber" 
              value="0" 
              hint="Our table numbers are 1-20"
              @input="$v.tableNumber.$touch()"
              @blur="$v.tableNumber.$touch()"
              :error-messages="tableNumberErrors"
              ></v-text-field>
            <v-btn class="primary rounded-tl-xl rounded-tr-md rounded-br-xl rounded-bl-md mt-5" @click="changeTable">Enter Table</v-btn>
          </v-form>
        </v-container>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, between } from 'vuelidate/lib/validators'

  export default {
    name: 'HelloWorld',
    mixins: [validationMixin],
    data: () => ({
      tableNumber: ''
    }),
    computed: {
      tableNumberErrors () {
        const errors = []
        if (!this.$v.tableNumber.$dirty) return errors
        !this.$v.tableNumber.required && errors.push('Nomor meja harus di isi')
        !this.$v.tableNumber.between && errors.push('Meja kami hanya nomor 1-20')
        return errors
      }
    },
    validations: {
      tableNumber: {
        required,
        between: between(1, 20)
      }
    },
    methods: {
      changeTable: function () {
        this.$v.$touch()
        if (this.$v.$invalid) {
          // alert('Your table is invalid!')
          console.log(this.$v)
         } else {
          this.$router.push('/'+this.tableNumber) 
        }
      }
    }
  }
</script>


<style>
.h-100 {
  height: 100%;
}
</style>