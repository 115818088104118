<template>
  <v-container class="h-100">
    <v-row class="text-center h-100" justify="center" align="center">
      <!-- <v-col cols="12">
        <v-img
          :src="require('../assets/logo.svg')"
          class="my-3"
          contain
          height="200"
        />
      </v-col> -->

      <v-col class="mb-4 text-center">
        <h1 class="display-3 font-weight-bold mb-3">
         Recents
        </h1>
        <h2 class="display-1 font-weight-bold mb-3">
         You are currently at <b>Table {{tableNumber}}</b>
        </h2>
        
        <!-- {{JSON.stringify($route)}} -->
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
  import TableMixins from '../mixins/TableValidation'

  export default {
    name: 'Recents',
    mixins: [TableMixins],
    data: () => ({
    }),
  }
</script>


<style>
.h-100 {
  height: 100%;
}
</style>