<template>
  <v-app>
    <v-container class="col-md-6" fill-height>    
      <v-main>
        <!-- <HelloWorld/> -->
        <router-view/>
      </v-main>
      <BottomNav />
    </v-container>
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';
import BottomNav from './components/BottomNav';

export default {
  name: 'App',

  components: {
    BottomNav,
    // HelloWorld
  },

  data: () => ({
    //
    showMenu: false
  }),
  
  methods: {
    toggleMenu () {
      this.showMenu = !this.showMenu
    }
  }
};
</script>
